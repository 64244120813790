<template>
    <div>
      <b-overlay :show="show" no-wrap fixed z-index="9999">
        <template v-slot:overlay>
            <div class="d-flex align-items-center">
              <b-spinner small type="grow" variant="dark"></b-spinner>
              <b-spinner type="grow" variant="dark"></b-spinner>
              <b-spinner small type="grow" variant="dark"></b-spinner>
            </div>
          </template>
      </b-overlay>
      <b-button variant="success" v-b-modal.new-modal>ثبت سوال جدید</b-button>
      <router-link to="/admin/faq/categories" class="btn btn-info float-left">دسته بندی ها</router-link>
      <b-card title="سوالات متداول" class="mt-3">

        <b-button v-b-toggle.filterBox variant="primary" class="mb-3 position-absolute" style="top:20px; left: 20px;"
        size="sm"><i class="fa fa-bars"></i></b-button>
      <b-collapse id="filterBox" class="my-2">
        <b-card>
          <form @submit.prevent="searchItems()" id="search-item">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="title">سوال</label>
                  <b-form-input id="title" v-model="searchParams.question"></b-form-input>
                </div>
              </div>
              <div class="col-md-4">
                <b-form-group label="دسته بندی" >
                  <multiselect open-direction="bottom" v-model="category" :options="categories" label="title" track-by="id" :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"></multiselect>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="وضعیت" label-for="status">
                  <b-form-select id="status" v-model="searchParams.status">
                    <b-form-select-option value="available">موجود</b-form-select-option>
                    <b-form-select-option value="unavailable">ناموجود</b-form-select-option>
                    <b-form-select-option value="inactive">غیرفعال</b-form-select-option>
                    <b-form-select-option value="soon">به زودی</b-form-select-option>
                    <b-form-select-option value="">همه</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </div>
            </div>
            <button class="btn btn-primary btn-sm float-left" type="submit" :disabled="disabled">جست و
              جو</button>
          </form>
        </b-card>
      </b-collapse>
        <b-table
          responsive
          striped
          hover
          v-if="items.data"
          :fields="table_fields"
          :items="items.data.data">

          <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | persianDate }}
          </template>
          <template v-slot:cell(status)="data">
            <span class="badge badge-success" v-if="data.item.status == 1">فعال</span>
            <span class="badge badge-light" v-else>غیرفعال</span>
          </template>

          <template v-slot:cell(edit)="data">
              <button class="btn btn-primary" @click="editItem(data.index)"><i class="fa fa-edit"></i></button>
          </template>
          <template v-slot:cell(category)="data">
              {{ data.item.category ? data.item.category.title : categories.filter(item => item.id == data.item.category_id)[0].title }}
          </template>
          <template v-slot:cell(delete)="data">
              <button class="btn btn-danger" @click="deleteItem(data.item.id)"><i class="fa fa-close"></i></button>
          </template>
        </b-table>
        <div class="alert alert-danger text-right" v-if="items.data && items.data.data == ''">
          <span class="text-right">موردی یافت نشد ! </span>
        </div>
      </b-card>

      <pagination :limit="2" v-if="items.data && !search" :data="items.data" @pagination-change-page="loadItems">
      </pagination>
      <pagination :limit="2" v-else-if="items.data && search" :data="items.data" @pagination-change-page="searchItems">
      </pagination>

      <b-modal id="new-modal" title="ثبت سوال" hide-footer>
        <div>
          <form @submit.prevent="newItem" id="new-item">
            <b-form-group >
              <label for="question"> سوال <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
              <b-form-textarea id="question" name="question" :disabled="disabled"></b-form-textarea>
            </b-form-group>

            <b-form-group >
              <label for="answer"> جواب <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                <b-form-textarea id="answer" name="answer" :disabled="disabled"></b-form-textarea>
              </b-form-group>

              <b-form-group >
                <label for="category_id"> دسته بندی <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                <b-form-select id="category_id" name="category_id" :disabled="disabled">
                  <b-form-select-option :value="item.id" v-for="(item, index) in categories" :key="index">{{item.title}}</b-form-select-option>
                </b-form-select>
              </b-form-group>

              <div class="form-group">
                <label class="custom-switch">
                    <input type="checkbox" name="status" class="custom-switch-input" v-model="selected_status" :disabled="disabled">
                    <span class="custom-switch-indicator"></span>
                    <span class="custom-switch-description">وضعیت</span>
                </label>
              </div>
            <div class="clearfix">
              <button class="btn btn-success float-right" type="submit" :disabled="disabled">ثبت</button>
              <b-button class="float-left" variant="white" type="button" @click="$root.$emit('bv::hide::modal', 'new-modal')">بستن</b-button>
            </div>
          </form>
        </div>
      </b-modal>

      <b-modal id="edit-modal" title="ویرایش سوال" hide-footer>
        <div v-if="edit.id">
          <form @submit.prevent="itemEdited" id="edit-item">
            <b-form-group  label-for="question">
              <label for="question"> سوال <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                <b-form-textarea id="question" name="question" v-model="edit.question" :disabled="disabled"></b-form-textarea>
              </b-form-group>

              <b-form-group >
                <label for="answer"> جواب <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                  <b-form-textarea id="answer" name="answer" v-model="edit.answer" :disabled="disabled"></b-form-textarea>
                </b-form-group>

                <b-form-group >
                  <label for="category_id"> دسته بندی <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                  <b-form-select id="category_id" name="category_id" v-model="edit.category_id" :disabled="disabled">
                    <b-form-select-option :value="item.id" v-for="(item, index) in categories" :key="index">{{item.title}}</b-form-select-option>
                  </b-form-select>
                </b-form-group>

                <div class="form-group">
                  <label class="custom-switch">
                      <input type="checkbox" name="status" class="custom-switch-input" v-model="edit.status" :disabled="disabled">
                      <span class="custom-switch-indicator"></span>
                      <span class="custom-switch-description">وضعیت</span>
                  </label>
                </div>
            <div class="clearfix">
              <button class="btn btn-primary float-right" type="submit" :disabled="disabled">ویرایش</button>
              <b-button class="float-left" variant="white" type="button" @click="$root.$emit('bv::hide::modal', 'edit-modal')">بستن</b-button>
            </div>
            <input type="hidden" value="PUT" name="_method">
          </form>
        </div>
      </b-modal>
    </div>
  </template>

<script>
import mixins from '../mixins/mixins'

export default {
  mixins: [mixins],
  data () {
    return {
      url: '/api/admin/faq',
      title: 'سوالات متداول',
      categories: [],
      category: [],
      faq: [],
      table_fields: [
        { key: 'id', label: 'شناسه ' },
        { key: 'question', label: 'سوال' },
        { key: 'category', label: 'دسته بندی' },
        { key: 'status', label: 'وضعیت' },
        { key: 'created_at', label: 'تاریخ ثبت' },
        { key: 'edit', label: 'ویرایش ' },
        { key: 'delete', label: 'حذف' }
      ],
      selected_status: '1',
        selected_filter_status: '',
    }
  },
  methods: {
    loadFaq () {
      window.$.get(this.baseUrl + '/faq', response => {
        if (response.success) {
          this.faq = response.data.data
          this.show = false
        }
      })
    },
    moment (...args) {
      return window.moment(...args)
    },
    loadCategories () {
      this.$axios.get(this.$root.baseUrl + '/api/admin/category/faq')
      .then(response => {
        this.categories = response.data.data.data
      })
    },
    searchItems(page = 1) {
            this.disabled = true
            this.search = true
            this.category != null ? this.searchParams.category_id = this.category.id : ''
    
            this.$axios.get(this.$root.baseUrl + this.url + '?page=' + page , {params: this.searchParams})
              .then(response => {
                this.search = true
                this.items = response.data
              })
              .catch(error => {
                this.$root.error_notification(error)
              })
              .finally(() => {
                this.disabled = false
              })
          },
  },
  created () {
    // this.loadFaq()
    this.loadItems()
    this.loadCategories()
    this.searchParams = {
        question: '',
        status: '',
        category_id: ''
      }
  }
}
</script>
